<template>
  <div v-bind="$attrs" class="combobox-wrapper_spire" :data-type="context.type">
    <vue-multiselect
      v-model="selectedOptions"
      :options="memberOptions"
      @select="addOption"
      :loading="isLoading"
      track-by="value"
      label="label"
      @search-change="asyncFind"
      :show-no-options="false"
      :hide-selected="true"
      :multiple="true"
      placeholder="Search by name or username"
    >
      <template #selection v-if="sortedSelectedOptions?.length > 0">
        <span class="multiselect__num-selected">
          {{ numSelectedAriaText }}
        </span>
      </template>
    </vue-multiselect>
    <span class="sr-only" aria-live="assertive">
      {{ numSelectedAriaText }}
    </span>
    <formulate-combobox-alphabetized-columns
      v-if="alphabetizedColumnLayout"
      :selected-options="sortedSelectedOptions"
      :empty-state-message="emptyStateMessage"
      @delete-option="deleteOption"
    />
    <formulate-combo-box-flex-list
      v-else
      :selected-options="sortedSelectedOptions"
      :empty-state-message="emptyStateMessage"
      @delete-option="deleteOption"
    />

    <span class="sr-only" aria-live="assertive">{{ resultAriaText }}</span>
    <span class="sr-only" aria-live="polite">{{ confirmationAriaText }}</span>
  </div>
</template>

<script>
import { pluralize } from "../../utils/base.js";
import FormulateComboboxAlphabetizedColumns from "./FormulateComboBoxAlphabetizedColumns.vue";
import FormulateComboBoxFlexList from "./FormulateComboBoxFlexList.vue";
import VueMultiselect from "vue-multiselect";
const DEFAULT_SORT_ATTRIBUTE = "last_name";
const DEFAULT_OBJECT_NAME = "item";
const DEFAULT_EMPTY_STATE_MESSAGE = "No items selected yet!";
const SEARCH_DEBOUNCE_MS = 1000;
import { mapActions, mapGetters } from "vuex";
import { debounce } from "lodash";
export default {
  name: "CourseMembershipComboBox",
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  components: {
    FormulateComboboxAlphabetizedColumns,
    FormulateComboBoxFlexList,
    VueMultiselect,
  },
  emits: ["add-option", "remove-option"],
  data() {
    return {
      searchText: "",
      activeIndex: null,
      confirmationAriaText: "",
      selectedOptions: [...this.context.attrs.options],
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(["eligibleCohortReviewers", "eligibleCohortMembers"]),
    resultAriaText() {
      let count = this.memberOptions?.count || 0;
      return `${count} ${pluralize("result", count)} found`;
    },
    numSelectedAriaText() {
      let pluralObjectName = pluralize(this.objectName, 0);
      return `${this.sortedSelectedOptions.length} selected ${pluralObjectName}`;
    },
    sortAttribute() {
      return this.context.attrs.sortBy || DEFAULT_SORT_ATTRIBUTE;
    },
    objectName() {
      return this.context.attrs.objectName || DEFAULT_OBJECT_NAME;
    },
    emptyStateMessage() {
      return (
        this.context.attrs.emptyStateMessage || DEFAULT_EMPTY_STATE_MESSAGE
      );
    },
    alphabetizedColumnLayout() {
      return this.context.attrs["alphabetized-column-layout"] || false;
    },
    sortedSelectedOptions() {
      return [...this.selectedOptions].sort((a, b) =>
        b[this.sortAttribute] < a[this.sortAttribute] ? 1 : -1,
      );
    },
    placeholderText() {
      return this.objectName ? `Search ${this.pluralObjectName}` : "Search";
    },
    memberOptions() {
      let source =
        this.context.id === "students"
          ? this.eligibleCohortMembers
          : this.eligibleCohortReviewers;
      return source.map((member) => ({
        label: `${member.user.full_name} (${member.user.username})`,
        value: member.user.id,
        last_name: member.user.last_name,
      }));
    },
  },
  methods: {
    ...mapActions(["loadUnpaginatedMembersList"]),
    addOption(option) {
      let node = this.context.node;
      let id = option.value;

      node.emit("add-option", id);
      node.input(this.selectedOptions);
      this.confirmationAriaText = `${option.label} has been added`;
      this.searchText = "";
    },
    deleteOption(option) {
      let node = this.context.node;
      let id = option.value;

      this.selectedOptions = this.selectedOptions.filter(
        (option) => option.value !== id,
      );
      node.emit("delete-option", id);
      node.input(this.selectedOptions);
      this.confirmationAriaText = `${option.label} has been deleted`;
    },
    asyncFind: debounce(async function (query) {
      this.isLoading = true;
      if (query?.length > 1) {
        let isStudent = this.context.id == "students";
        let roleValue = isStudent ? ["student"] : ["gsi", "professor"];
        this.loadUnpaginatedMembersList({
          search: query,
          roles: roleValue,
        }).then(() => {
          this.isLoading = false;
        });
      } else {
        this.isLoading = false;
      }
    }, SEARCH_DEBOUNCE_MS),
  },
};
</script>
