<template>
  <li>
    <spire-tag
      @click.prevent="deleteOption"
      :aria-label="`Delete ${option.label}`"
      :isRemovable="false"
      variant="removable"
      icon-right="fa-close"
    >
      {{ option.label }}
    </spire-tag>
  </li>
</template>

<script>
export default {
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
  emits: ["delete-option"],
  methods: {
    deleteOption() {
      this.$emit("delete-option", this.option);
    },
  },
};
</script>
